import React from "react";
import "../styles/PageNotFound.scss";

function PageNotFound() {
  return (
    <div className="Unknown">
      <p>Page Not Found :(</p>
      <a href="/">Go to Home</a>
    </div>
  );
}

export default PageNotFound;
